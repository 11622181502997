<template>
     <div class="id_auth">
          <MemberTitle memberTitle="银行卡"></MemberTitle>
          <div v-if="stateNow == 'do'">
               <div class="id_auth_container">
                    <table>
                         <tr>
                              <td>
                                   <ComponentA name="姓名" need="true"></ComponentA>
                              </td>
                              <td><span class="font333">{{ accountInfo.personName }}</span></td>
                         </tr>
                         <tr>
                              <td>
                                   <ComponentA name="身份证号" need="true"></ComponentA>
                              </td>
                              <td>
                                   <span class="font333">{{ accountInfo.personIds }}</span>
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   <ComponentA name="银行卡号" need="true"></ComponentA>
                              </td>
                              <td>
                                   <input type="number" placeholder="请输入本人银行卡卡号" v-model="bankNo" class="class_input">
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   <ComponentA name="银行预留手机号" need="true"></ComponentA>
                              </td>
                              <td>
                                   <input type="number" placeholder="请输入银行预留手机号" v-model="phoneNo" class="class_input">
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   <ComponentA name="验证码" need="true"></ComponentA>
                              </td>
                              <td>
                                   <div class="flex_row_start_center">
                                        <input type="number" v-model="smsCode" class="class_input1"
                                             placeholder="请输入验证码">
                                        <div class="sms_code" @click="sendSms">
                                             {{ countDown ? `${countDown}s` : '获取验证码' }}
                                        </div>
                                   </div>
                              </td>
                         </tr>
                         <tr>
                              <td>
                                   <ComponentA name="默认" need="false"></ComponentA>
                              </td>
                              <td>
                                   <el-switch @change="defaultChange(item, $event)" v-model="isDefault"
                                        :active-value="1" :inactive-value="0" />
                              </td>
                         </tr>
                         <tr>
                              <td></td>
                              <td>
                                   <Button text="下一步" @click="next"></Button>
                              </td>
                         </tr>
                    </table>
               </div>
          </div>
          <div class="result" v-else>
               <AuthResult></AuthResult>
          </div>

     </div>
</template>

<script>
import { defineComponent, ref, reactive, getCurrentInstance, onMounted } from 'vue'
import MemberTitle from '@/components/MemberTitle';
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
export default {
     components: {
          MemberTitle
     },
     setup() {
          const { proxy } = getCurrentInstance()
          const accountInfo = ref({})
          const router = useRouter()
          const route = useRoute()
          const cardState = reactive({
               successToSign: {
                    style: 'width:101px;height:102px',
                    img: require('@/assets/success.png'),
                    text: '绑卡成功，请进行签约',
                    btnBg: 'gray_btn',
                    btnTxt: '去签约'
               },

               signSuccess: {
                    style: 'width:340px;height:220px',
                    img: require('@/assets/empty_card.png'),
                    text: '签约成功',
                    btnBg: 'gray_btn',
                    btnTxt: '返回'
               },

               success: {
                    style: 'width:101px;height:102px',
                    img: require('@/assets/success.png'),
                    text: '绑卡成功',
                    btnBg: 'gray_btn',
                    btnTxt: '返回'
               },

               do: {

               }
          })

          const stateNow = ref('do')
          const bankNo = ref('')
          const phoneNo = ref('')
          const isDefault = ref(0)
          const tranceNum = ref(0)
          const smsCode = ref('')
          const countDown = ref(0)
          const timer = ref(null)
          const nextPrevent = ref(false)
          const isSendSms = ref(false)
          const getAccount = () => {
               proxy.$get('v3/member/front/openAccount/detail').then(res => {
                    if (res.state == 200) {
                         accountInfo.value = res.data
                    }
               })
          }

          const varifyAccount = () => {
               proxy.$get('v3/member/front/openAccount/detail').then(res => {
                    if (res.state == 200) {
                         accountInfo.value = res.data
                         if (res.data.state == 2) {
                              stateNow.value = 'success'
                         } else {
                              stateNow.value = 'successToSign'
                         }
                    }
               })
          }


          const binding = () => {
               proxy.$post('v3/member/front/openAccount/confirmBindCard', {
                    bankAccount: bankNo.value,
                    bankAccountPhone: phoneNo.value,
                    isDefault: isDefault.value,
                    tranceNum: tranceNum.value,
                    verificationCode: smsCode.value

               }).then(res => {
                    if (res.state == 200) {
                         nextPrevent.value = false
                         varifyAccount()
                    } else {
                         nextPrevent.value = false
                         ElMessage.error(res.msg)
                    }
               })
          }

          const next = () => {

               switch (stateNow.value) {
                    case 'do': {

                         if (nextPrevent.value) {
                              return
                         }
                         nextPrevent.value = true


                         if (!bankNo.value) {
                              ElMessage.warning('请输入银行卡号');
                              return
                         } else if (bankNo.value.length > 19) {
                              ElMessage.warning('银行号输入超过个数限制')
                              return
                         }

                         if (proxy.$checkPhone(phoneNo.value) != true) {
                              ElMessage.warning(proxy.$checkPhone(phoneNo.value))
                              return
                         }


                         if (!isSendSms.value) {
                              ElMessage.warning('请先发送验证码')
                              return
                         }

                         if (!smsCode.value) {
                              ElMessage.warning('请输入验证码')
                              return
                         }

                         binding()
                         break
                    }

                    case 'successToSign': {

                         if (nextPrevent.value) {
                              return
                         }
                         nextPrevent.value = true



                         proxy.$get('v3/member/front/openAccount/sign', {
                              jumpPageType: 1,
                              source: 2
                         }).then(res => {
                              if (res.state == 200) {
                                   nextPrevent.value = false
                                   window.location.href = res.data
                              } else {
                                   ElMessage.error(res.msg)
                              }
                         })
                         break
                    }

                    case 'success': {
                         router.back()
                         break
                    }
               }
          }

          const pre = ref(false)
          const sendSms = () => {

               if (!bankNo.value) {
                    ElMessage.warning('请填写银行卡号')
                    return
               }

               if (!phoneNo.value) {
                    ElMessage.warning('请填写手机号')
                    return
               }

               if (pre.value) {
                    return
               }
               pre.value = true
               isSendSms.value = true
               proxy.$post('v3/member/front/openAccount/applyBindCard', {
                    bankAccount: bankNo.value,
                    bankAccountPhone: phoneNo.value
               }).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         tranceNum.value = res.data
                         countDown.value = 60
                         toCountDown()
                    } else {
                         ElMessage.warning(res.msg)
                         pre.value = false
                         isSendSms.value = false
                    }
               })
          }

          const toCountDown = () => {
               countDown.value--
               if (countDown.value == 0) {
                    pre.value = false
                    isSendSms.value = false
                    clearTimeout(timer.value)
               } else {
                    timer.value = setTimeout(toCountDown, 1000)
               }
          }




          onMounted(() => {

               getAccount()
          })

          const ComponentA = (props, context) => {
               return (
                    <div className="leftName flex_row_end_center">
                         {props.need == 'true' ? <span className="red_dot">*</span> : ''}
                         <div className="font333">{props.name}：</div>
                    </div>

               )
          }


          const AuthResult = (props, context) => {

               let { text, btnTxt, img, style } = cardState[stateNow.value]

               return (
                    <div style="width:100%;" className="res_state flex_column_center_center">
                         <img src={img} style={style} />
                         <p>{text}</p>
                         <div className="button_primary" onClick={next}>{btnTxt}</div>
                    </div>
               )
          }

          const Button = (props, context) => {
               return (
                    <div className="button_primary">{props.text}</div>
               )
          }



          return {
               ComponentA,
               Button,
               next,
               bankNo,
               phoneNo,
               isDefault,
               tranceNum,
               smsCode,
               accountInfo,
               countDown,
               AuthResult,
               stateNow,
               sendSms
          }

     },
}
</script>


<style lang="scss">
.id_auth {
     width: 1020px;
     float: left;
     margin-left: 10px;

     .id_auth_container {
          background: #fff;
          padding: 15px;
          padding-left: 229px;
          padding-top: 68px;

          .font333 {
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #121212;
          }


          .leftName {
               text-align: right;

               .red_dot {
                    color: $colorMain;
                    font-size: 16px;
                    margin-right: 5px;
               }
          }

          table {
               border-collapse: separate;
               border-spacing: 31px;

               .class_input {
                    width: 360px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    border-radius: 2px;
                    padding: 0 10px;
                    font-size: 15px;
               }

               .class_input1 {
                    width: 230px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    border-radius: 2px;
                    padding: 0 10px;
                    font-size: 15px;
               }

               .sms_code {
                    width: 120px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    border-radius: 2px;
                    text-align: center;
                    line-height: 40px;
                    margin-left: 10px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    cursor: pointer;
               }
          }
     }

     .result {
          background: #fff;
          height: 970px;

          .res_state {
               padding-top: 100px;

               p {
                    margin-top: 31px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
               }

               span {
                    margin-top: 16px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 31px;
               }
          }

          .button_primary {
               width: 150px;
               height: 40px;
               background: #E73439;
               border-radius: 4px;
               text-align: center;
               font-size: 18px;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #FFFFFF;
               line-height: 40px;
               cursor: pointer;
               margin-top: 30px;
          }
     }

     .button_primary {
          width: 150px;
          height: 40px;
          background: #E73439;
          border-radius: 4px;
          text-align: center;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 40px;
          cursor: pointer;
     }
}
</style>